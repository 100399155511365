yaModules.forms = (function () {

    let $buttonFieldClear = $('.ya-field-clear');

    function addEventListeners() {
        $buttonFieldClear.on('click', clearField);
    }

    function clearField() {
        let $field = $(this).parent().find('.ya-field');

        $field.val('');
        $field.typeahead('val','');
        $field.focus();
    }

    return {
        init() {
            if ($buttonFieldClear.length) {
                addEventListeners();
            }
        }
    }
}());
