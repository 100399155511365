yaModules.yaFixedHeader = (function() {

    let $fixedHeader = undefined;
    let $containerFixedHeader = $('.ya-header__fixed-container');
    let heightHeader = $('.ya-header').outerHeight();
    let windowScrollTop = 0;

    /**
     * Добавление обработчиков
     */
    function addEventListeners() {
        $(window).on('scroll load', controlHeader);
    }

    function controlHeader() {
        let scrollLeft = $(window).scrollLeft();
        windowScrollTop = $(window).scrollTop();
        if(!$fixedHeader) $fixedHeader = $('.ya-header__fixed');

        setPositionHeader(scrollLeft);
        controlVisibleFixedHeader();
    }

    /**
     * Установка позиции фиксированного блока при горизонтальной прокрутке страницы
     */
    function setPositionHeader(scrollLeft) {
        if(scrollLeft < 0) return;

        $fixedHeader.each(function() {
            $(this).css('left', -scrollLeft);
        });
    }

    function setHeightContainer() {
        let height = $containerFixedHeader.outerHeight();

        $containerFixedHeader.css('height', height);
    }

    function controlVisibleFixedHeader() {
        if(windowScrollTop > heightHeader) {
            $fixedHeader.addClass('ya-visible');
        }else{
            $fixedHeader.removeClass('ya-visible');
        }
    }

    return {
        init() {
            if($('.ya-header__fixed').length) {
                // setHeightContainer();
                addEventListeners();
                controlHeader();
            }
        }
    }

})();

