yaModules.card = (function () {

    function initSliders() {
        let sliderMain = new YaSlider('.ya-slider-container--card', {
            //effect: 'carousel',
            duration: 600,
            delayAfterClick: 600,
            loop: true
        });
        let sliderNav = new YaSlider('.ya-slider-container--card-nav', {
            effect: 'carousel',
            duration: 600,
            slideClicked: true,
            loop: true,
        });

        sliderMain.setControl(sliderNav);
        sliderNav.setControl(sliderMain);
    }

    return {
        init() {
            if ($('.ya-card').find('.ya-slider-container').length) {
                initSliders();
            }
        }
    }
}());
