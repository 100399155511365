yaModules.delivery = (function () {

    let $destinations = $('.ya-delivery__destination');
    let arrDestinations = [];
    let destinations = {};
    let markValue = null;
    let $typeahead = $('.ya-delivery').find('.ya-typeahead').find('input');
    let $buttonsShowMap = $('.ya-delivery__show-map');
    let isRoute = false;
    let $pageContainer = $('.ya-page');

    let idMap = 'ya-delivery-map';
    let centerMap = 'Краснодарскйи край';
    let map = null;

    function addEventListeners() {
        $typeahead.bind('typeahead:select typeahead:autocomplete typeahead:change', findDestionations);
        $('.ya-delivery__find form').on('submit', findDestionations);
        $buttonsShowMap.on('click', showDestinationsInMap);
    }

    function initTypeahead() {
        yaModules.typeahead.create($typeahead, arrDestinations);
    }

    function findDestionations(ev) {
        ev.preventDefault();
        if(markValue) {
            destinations[markValue].each(function() {
                $(this).removeClass('ya-mark');
            });
        }
        let value = $typeahead.typeahead('val');
        markValue = value;
        if(destinations[value]) {
            destinations[value].each(function() {
                $(this).addClass('ya-mark');
            });
        }
    }

    function getArrDestinations() {
        $destinations.each(function() {
            let $el = $(this);
            let value = $el.text();

            if(destinations[value]) {
                destinations[value].push($el);
            }else{
                destinations[value] = $el;
            }

            if(arrDestinations.indexOf(value) < 0) {
                arrDestinations.push(value);
            }
        });
    }

    function createMap() {
        map = new MapYandex({
            mapContainer: idMap,
            center: centerMap,
            zoom: 7,
            //marker: coords,
            //markerSrc: '/static/img/general/marker-2x.png',
            //markerSize: [41,47],
            //markerOffset: [0,-45]
        });
    }

    function showDestinationsInMap() {
        if(isRoute) return;
        isRoute = true;
        $pageContainer.addClass('ya-is-progress');

        let $destinations = $(this).parents('tr').find('.ya-delivery__destination');
        let data = [];

        $destinations.each(function() {
            data.push($(this).data('address'));
        });
        smoothScroll.animateScroll('#ya-delivery-map-container');
        map.addRoute(data)
            .then(function() {
                isRoute = false;
                $pageContainer.removeClass('ya-is-progress');
            }, function() {
                isRoute = false;
                $pageContainer.removeClass('ya-is-progress');
            });
    }


    return {
        init() {
            if ($destinations.length) {
                getArrDestinations();
                initTypeahead();
                addEventListeners();
                createMap();
            }
        }
    }
}());
