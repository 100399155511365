yaModules.yaMenu = (function () {

    let $buttonOpenMenu = $('.ya-open-menu');
    let $menuLi = $buttonOpenMenu.parents('.ya-menu__li');

    function addEventListeners() {
        $buttonOpenMenu.on('click', toggleMenu);
        $menuLi.on('mouseleave', closeMenu);
    }

    function toggleMenu() {
        $menuLi.toggleClass('ya-visible');
    }

    function closeMenu() {
        $menuLi.removeClass('ya-visible');
    }

    return {
        init() {
            if ($buttonOpenMenu.length) {
                addEventListeners();
            }
        }
    }
}());
