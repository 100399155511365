yaModules.offer = (function () {

    let $buttonsHideOffer = $('.ya-offer-hide');

    function addEventListeners() {
        $buttonsHideOffer.on('click', hideOffer);
    }

    function hideOffer() {
        $(this).parents('.ya-offer').remove();
    }

    function initCalendar() {
        let minDate = new Date();
        minDate.setDate(minDate.getDate() + 1);
        $('.datepick').each(function() {
            $(this).pickmeup({
                flat: true,
                min: minDate,
                locale			: {
                    days		: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье'],
                    daysShort	: ['Вос', 'Пон', 'Вто', 'Сре', 'Чет', 'Пят', 'Суб', 'Вос'],
                    daysMin		: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
                    months		: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                    monthsShort	: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек']
                },
                change: setValueInputDatepick,
                render: setValueInputDatepick
            }).pickmeup('set_date', minDate);
        });
        $('.pmu-prev').each(function() {
            $(this).empty().append('<span class="ya-icon ya-icon--pointer-left-4">');
        });
        $('.pmu-next').each(function() {
            $(this).empty().append('<span class="ya-icon ya-icon--pointer-right-4">');
        });
    }

    function setValueInputDatepick() {
        let $datepick = $(this);
        let value = $datepick.pickmeup('get_date', true);
        let $input = $datepick.parent().find('[name=datepick]');
        $input.val(value);
    }

    return {
        init() {
            if ($buttonsHideOffer.length) {
                initCalendar();
                addEventListeners();
            }
        }
    }
}());
