yaModules.selection = (function () {

    let $buttonToggleCategories = $('.ya-selection__steps-cat-toggle');
    let $categoriesList = $('.ya-selection__steps-cat.ya-full');
    let classVisible = 'ya-visible';

    function addEventListeners() {
        $buttonToggleCategories.on('click', toggleCategories);
    }

    function toggleCategories(ev) {
        ev.preventDefault();

        if($categoriesList.hasClass(classVisible)) {
            let newButtonText = $buttonToggleCategories.data('text-hide');
            $buttonToggleCategories.text(newButtonText);
            $categoriesList.removeClass(classVisible).stop().slideUp(300);
        }else{
            let newButtonText = $buttonToggleCategories.data('text-show');
            $buttonToggleCategories.text(newButtonText);
            $categoriesList.addClass(classVisible).stop().slideDown(300);
        }
    }

    function templateAutocomplete() {
        yaModules.typeahead.create($('[name*=selection]'));
    }

    return {
        init() {
            if ($buttonToggleCategories.length) {
                addEventListeners();
            }
            if($('.ya-selection').length) {
                templateAutocomplete();
            }
        }
    }
}());
