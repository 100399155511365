yaModules.filter = (function () {

    let $typeaheads = $('[name*=ft-]');

    return {
        init() {
            if ($typeaheads.length) {
                yaModules.typeahead.create($typeaheads);
            }
        }
    }
}());
