yaModules.chosen = (function () {

    let $fieldsChosen = $('.ya-field-chosen');

    function initChosen() {
        $fieldsChosen.each(function() {
            $(this).chosen({disable_search_threshold:10});
        });
    }

    return {
        init() {
            if ($fieldsChosen.length) {
                initChosen();
            }
        }
    }
}());
