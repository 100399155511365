yaModules.login = (function () {

    let $fields = $('.ya-login__reg-form').find('.ya-field');

    function addEventListeners() {
        $(document).on('ya-tabs-changed', toggleDisabledFields);
    }

    function toggleDisabledFields() {
        let $currentFields = $('.ya-tabs__list-li.ya-tabs-active').find('.ya-field');

        $fields.prop('disabled', true);
        $currentFields.prop('disabled', false);
    }

    return {
        init() {
            if ($fields.length) {
                addEventListeners();
            }
        }
    }
}());
