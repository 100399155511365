yaModules.yaSlider = (function () {

    function initSlider() {
        let sliderMain = new YaSlider('.ya-slider-container--ad', {
            effect: 'carousel',
            duration: 600,
            loop: true,
            autoplay: 10000,
            responsive: true
        });
    }

    return {
        init() {
            if ($('.ya-slider-container').length) {
                initSlider();
            }
        }
    }
}());
